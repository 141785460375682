import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';


function initPhotoSwipeLightbox() {
    const galleryElements = document.querySelectorAll('.gallery_wrapper');

    galleryElements.forEach((galleryElement) => {
        const lightbox = new PhotoSwipeLightbox({
            gallery: '.gallery_wrapper',
            children: 'a.thumb_link',
            showHideAnimationType: 'zoom',
            pswpModule: PhotoSwipe,
        });

        // lightbox.on('itemData', (e) => {
        //     console.log(e)
        //     // const dataset = e.itemData.element.dataset;
        //     //
        //     // e.itemData = {
        //     //     src: dataset.largeSrc,
        //     //     w: parseInt(dataset.largeSrcWidth),
        //     //     h: parseInt(dataset.largeSrcHeight),
        //     //     title: "title",
        //     // };
        // });

        lightbox.init();
    });
}

document.addEventListener('DOMContentLoaded', initPhotoSwipeLightbox);
